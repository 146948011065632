import * as React from "react"


const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>

    </main>
  )
}

export default NotFoundPage
